import { createGlobalStyle } from 'styled-components'

const regular = '../assets/fonts/Lato-Regular.ttf'
const bold = '../assets/fonts/Lato-Bold.ttf'
const thin = '../assets/fonts/Lato-Thin.ttf'

const Typography = createGlobalStyle`
  @font-face {
    font-family: LatoRegular;
    src: url(${regular});
  }

  @font-face {
    font-family: LatoBold;
    src: url(${bold});
  }

  @font-face {
    font-family: LatoThin;
    src: url(${thin});
  }

  html {
    font-family: LatoRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }

  p, li {
    letter-spacing: 0.5px;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }

  a {
    color: var(--black);
    text-decoration: none;
    overflow-wrap: break-word;

    &:hover {
      color: var(--link-hover)
    }
  }

  .center {
    text-align: center;
  }

  a.dark {
    color: var(--link-hover);
    text-decoration: none;

    &:hover {
      color: #518de8;
    }
  }

  a.subtle {
    color: #000000;
    text-decoration: none;

    &:hover {
      color: #444444;
    }
  }

  a.light {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: var(--link-hover);
    }
  }

  a.no-hover {
    &:hover {
      color: inherit;
    }
  }

  h1.title {
    margin-bottom: 20px;
    text-decoration: underline;
  }
`

export default Typography
