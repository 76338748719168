import styled from 'styled-components'

const GlobalStyles = styled.div`
  h4 {
    text-align: center;
    /* margin-bottom: 20px; */
  }
  
  form {
    margin: 0 auto;
    max-width: 1000px;
    min-width: 300px;

    .title {
      text-align: center;
    }

    .section {
      margin-bottom: 20px;

      &.end {
        margin-bottom: 0;
      }

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;


      .header {
        width: 100%;
        margin-bottom: 10px;

        .section-title {
          padding: 0;
          margin: 0;
          font-size: 2.5rem;
          /* margin-bottom: 10px; */
          text-decoration: underline;
        }
        .description {
          padding: 0;
          margin: 0;
          margin-top: 10px;
          margin-bottom
        }

      }
      
      .half {
        /* min-width: 300px; */
        width: 100%;
      }
      .full {
        /* min-width: 300px; */
        width: 100%;
      }

      .right {
        text-align: right;
      }
      gap: 10px;

    }

    input {
      width: 100%;
      height: 35px;
      margin-right: 10px;
    }

    textarea {
      width: 100%;
      height: 40vh;
      padding: 5px;
    }

    button {
      margin-top: 10px;
      border: 1px solid #ddd;
      padding: 10px 20px;
      border-radius: 8px;
      cursor: pointer;
    }

    button[type=submit] {
      justify-self: flex-end;
      background-color: var(--link-hover);
      color: white;
    }
  }

  @media all and (min-width: 700px) {
    form {
      .section {
        padding: 10px;

        .header {
          .section-title {

            font-size: 3.3rem;
          }
        }

        .half {
          width: 48%;
        }
      }
    }
  }

`

export default GlobalStyles
