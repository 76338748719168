// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-district-officers-js": () => import("./../../../src/pages/about/district-officers.js" /* webpackChunkName: "component---src-pages-about-district-officers-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-find-a-chorus-js": () => import("./../../../src/pages/find-a-chorus.js" /* webpackChunkName: "component---src-pages-find-a-chorus-js" */),
  "component---src-pages-history-district-of-firsts-js": () => import("./../../../src/pages/history/district-of-firsts.js" /* webpackChunkName: "component---src-pages-history-district-of-firsts-js" */),
  "component---src-pages-history-expanded-history-history-by-year-js": () => import("./../../../src/pages/history/expanded-history/history-by-year.js" /* webpackChunkName: "component---src-pages-history-expanded-history-history-by-year-js" */),
  "component---src-pages-history-expanded-history-index-js": () => import("./../../../src/pages/history/expanded-history/index.js" /* webpackChunkName: "component---src-pages-history-expanded-history-index-js" */),
  "component---src-pages-history-index-js": () => import("./../../../src/pages/history/index.js" /* webpackChunkName: "component---src-pages-history-index-js" */),
  "component---src-pages-history-international-champions-js": () => import("./../../../src/pages/history/international-champions.js" /* webpackChunkName: "component---src-pages-history-international-champions-js" */),
  "component---src-pages-history-scoresheets-plateaus-js": () => import("./../../../src/pages/history/scoresheets/plateaus.js" /* webpackChunkName: "component---src-pages-history-scoresheets-plateaus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pio-links-js": () => import("./../../../src/pages/pio-links.js" /* webpackChunkName: "component---src-pages-pio-links-js" */),
  "component---src-templates-education-item-js": () => import("./../../../src/templates/EducationItem.js" /* webpackChunkName: "component---src-templates-education-item-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-hall-of-fame-item-js": () => import("./../../../src/templates/HallOfFameItem.js" /* webpackChunkName: "component---src-templates-hall-of-fame-item-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/NewsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-pagination-lists-district-champions-js": () => import("./../../../src/templates/paginationLists/district-champions.js" /* webpackChunkName: "component---src-templates-pagination-lists-district-champions-js" */),
  "component---src-templates-pagination-lists-hall-of-fame-js": () => import("./../../../src/templates/paginationLists/hall-of-fame.js" /* webpackChunkName: "component---src-templates-pagination-lists-hall-of-fame-js" */),
  "component---src-templates-pagination-lists-scoresheets-js": () => import("./../../../src/templates/paginationLists/scoresheets.js" /* webpackChunkName: "component---src-templates-pagination-lists-scoresheets-js" */)
}

