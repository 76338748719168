import React from 'react'
import styled from 'styled-components'

const ButtonStyles = styled.div.attrs(props => {
  const finalAlignment = props.alignment || 'center'

  return {
    alignment: finalAlignment,
  }
})`
  display: flex;
  justify-content: ${props => props?.alignment || 'center'};
  margin: 0;
  div {
    margin: 0;
    padding: 0;
  }

  p {
    border: 1px solid black;
    border-radius: 5px;
    margin: 0;
    margin: 10px;
    /* margin-top: 15px; */
    padding: 15px 25px;
    pointer-events: auto;
    cursor: pointer;

    background-color: #65b0aa;
    color: white;

    &:hover {
      background-color: #2a4169;
      color: white;
    }
    &:active {
      background-color: #65b0aa;
    }
  }

  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

export default function DonateButton({ title, alignment }) {
  return (
    <ButtonStyles alignment={alignment}>
      <div>
        <a
          href="https://buy.stripe.com/4gweXE5dX43s9cQ9AA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="unselectable">{title}</p>
        </a>
      </div>
    </ButtonStyles>
  )
}
