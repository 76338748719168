import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --blue: blue;

    --link-hover: #286ed6;
    --button-normal:#518de8;

    --general-dark: #222;


    --tablet: 700px;
    --desktop: 1136px;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 8px;
  }

  body {
    font-size: 2rem;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  .full-width {
    width: 100%;
  }

  .content-width {
    width: 80%;
    margin: 10px auto;
  }

  .hover {
    transform: scale(1);
    transition: all 0.5s;
    &:hover {
      transform: scale(1.02);
    }
  }

  @media all and (min-width: 700px) {
    html {
      font-size: 10px;
    }

    body {
      /* font-size: 1.5rem */
    }
  }

  @media all and (min-width: 1136px) {
    html {
      font-size: 10px;
      /* font-size: 2rem; */
    }
  }

  .item-wrapper {
    padding: 10px 20px;
    border: 1px solid black;
    box-shadow: 2px 2px 5px #999;
  }

`

export default GlobalStyles
