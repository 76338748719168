import styled from 'styled-components'

const NavStyles = styled.div`
  /* Basic styling */
  * {
    padding: 0;
    margin: 0;
  }

  #spacer {
    height: 90px;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9999;
    background: var(--general-dark);
    padding: 0 15px;
    max-height: 100vh;
    overflow: scroll;
  }

  .logo {
    padding: 5px;
    .logo-content {
      display: flex;
      align-items: center;
      /* justify-content: center; */
      img {
        margin-right: 10px;
        height: 50px;
      }
      h3 {
        display: none;
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
  }
  .menu,
  .submenu {
    list-style-type: none;
  }
  .logo {
    font-size: 20px;
    /* padding: 7.5px 10px 7.5px 0; */
  }
  .item {
    padding: 0 10px;
  }
  .item.button {
    padding: 9px 5px;
  }
  .item:not(.button) a:hover,
  .item a:hover::after {
    color: #ccc;
  }

  .dropdown {
    display: flex;
    /* height: 100%; */
    align-items: middle;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    pointer-events: auto;

    .arrow {
      /* font-size: 2.5rem; */
      padding-top: 2px;
      height: auto;
      width: auto;
      top: 10px;
      transform: translateY(1%)
      transition: all 0.25s;
    }
  }

  /* Mobile menu */
  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .menu li a {
    display: block;
    padding: 15px 5px;
  }
  .menu li.subitem a {
    padding: 15px;
  }
  .toggle {
    color: #fff;
    order: 1;
    font-size: 20px;
  }
  .item.button {
    order: 2;
  }
  .item {
    order: 3;
    width: 100%;
    text-align: center;
    display: none;
  }
  .active .item {
    display: block;
  }
  .button.secondary {
    /* divider between buttons and menu links */
    border-bottom: 1px #444 solid;
  }

  /* Submenu up from mobile screens */
  .submenu {
    display: none;
    transition: all 1s;
  }
  .submenu-active .submenu {
    display: block;
    margin: 0 auto;

    &.left-submenu {
      left: auto;
      right: 4px;
    }
  }
  .has-submenu i {
    font-size: 12px;
  }

  .subitem a {
    padding: 10px 15px;
  }
  .submenu-active {
    background-color: #111;
    border-radius: 3px;

    .arrow {
      transform: scaleY(-1);
    }
  }

  /* Tablet menu */
  @media all and (min-width: 700px) {
    .menu {
      justify-content: center;
    }
    .logo {
      flex: 1;
    }
    .item.button {
      width: auto;
      order: 1;
      display: block;
    }
    .toggle {
      /* font-size: 120%; */
      flex: 1;
      text-align: right;
      order: 2;
    }
    /* Button up from tablet screen */
    .menu li.button a {
      padding: 10px 15px;
      margin: 5px 0;
    }
    .button a {
      background: #0080ff;
      border: 1px royalblue solid;
    }
    .button.secondary {
      border: 0;
    }
    .button.secondary a {
      background: transparent;
      border: 1px #0080ff solid;
    }
    .button a:hover {
      text-decoration: none;
    }
    .button:not(.secondary) a:hover {
      background: royalblue;
      border-color: darkblue;
    }
  }

  @media all and (min-width: 1136px) {
    nav {
      overflow: visible;

    }
    .menu {
      align-items: center;
      flex-wrap: nowrap;
      background: none;
    }
    .logo {
      order: 0;

      .logo-content {
        h3 {
          display: block;
        }
      }
    }
    .item {
      order: 1;
      position: relative;
      display: block;
      width: auto;
    }
    .button {
      order: 2;
    }
    .submenu {
      width: 250px;
    }
    .submenu-active .submenu {
      display: block;
      position: absolute;
      left: 0;
      top: 55px;
      background: #111;
      z-index: 9999;
    }
    .toggle,
    span {
      display: none;
    }
    .submenu-active {
      border-radius: 0;
    }
  }
`

export default NavStyles
