import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import _ from 'lodash'
import { Link } from 'gatsby'

const generateLink = (innerEl, navEl) => {
  const linkObj = navEl._rawLink
  const externalLink = _.get(linkObj, ['externalLink', 'href'], null)

  if (externalLink) {
    return (
      <a href={externalLink} target="_blank" rel="noopener noreferrer">
        {innerEl}
      </a>
    )
  }

  const { internalLink } = linkObj
  const types = Object.keys(internalLink).filter(l => l !== '_type')
  const populatedType = types.find(type => internalLink[type]._ref)

  const ref = internalLink[populatedType]._ref

  return <Link to={`/${populatedType}/${ref}`}>{innerEl}</Link>
}

const ElLink = ({ navEl, isDropdown }) => {
  let innerEl
  const { title } = navEl

  if (isDropdown) {
    innerEl = (
      <div className="dropdown">
        <p>{title}</p>
        <MdKeyboardArrowDown className="arrow" />
      </div>
    )
  } else {
    innerEl = <p>{title}</p>
  }

  if (isDropdown) {
    return <a>{innerEl}</a>
  }

  return generateLink(innerEl, navEl, isDropdown)
}

export { generateLink, ElLink }
