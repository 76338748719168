import styled from 'styled-components'

const FooterStyles = styled.div`
  background-color: var(--general-dark);
  color: white;
  margin: 0;
  padding: 10px 0;
  font-size: 1.3rem;
  /* position: absolute; */
  /* bottom: 0; */

  a {
    color: white;

    &:hover {
      color: #ccc;
    }
  }
  a:not(.button) a:hover,
  a:hover::after {
    color: #ff0000;
  }

  .inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* padding: 0; */
  }

  font-size: 1.3rem;
  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.8rem;
  }

  .line-break {
    margin: auto 10px;
  }

  .social {
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 2.8rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .item {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      .icon {
        position: relative;
        font-size: 2.3rem;
        padding: 0;
        margin: 0;
        width: 25px;

        &.facebook {
          color: #13a4fb;
        }

        &.insta {
          color: #dc3270;
        }

        .img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          align-self: center;
        }
      }

      a {
        padding: 0;
        margin: 0;
      }
    }
  }

  .credits-container {
    padding-bottom: 5px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin: 0;
    position: relative;
    height: 30px;

    .credits-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  .logo-container {
    height: auto;
    width: 100px;
    text-align: center;
    margin: 20px;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .donate-button {
    margin-top: 20px;
  }

  @media all and (min-width: 700px) {
    font-size: 2.4;
    .links {
    }
  }

  @media all and (min-width: 1136px) {
    font-size: 1.5rem;
  }
`

export default FooterStyles
