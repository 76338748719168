import React from 'react'
import styled from 'styled-components'
import 'normalize.css'
import { Helmet } from 'react-helmet'

import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import FormStyles from '../styles/FormStyles'
import Nav from './NavBar/Nav'
import NavLinks from './NavBar/QuickNav'
import Footer from './Footer/Footer'
import BackToTopButton from './backToTop'

const ContentStyles = styled.div`
  background: white;
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .underNav {
    /* min-height: 50vh; */
    /* margin-bottom: 30px; */
    /* padding-bottom: 350px; */
  }

  @media all and (min-width: 700px) {
    .underNav {
      /* padding-bottom: 300px; */
    }
  }

  /* padding: 2rem; */
`

export default function Layout({ children, location }) {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Pioneer District</title>
        <script async src="https://js.stripe.com/v3/buy-button.js" />
      </Helmet>
      <GlobalStyles />
      <Typography />
      <FormStyles />
      <ContentStyles>
        <Nav />
        <div className="underNav">
          <NavLinks location={location} />
          <div>{children}</div>
          <Footer />
        </div>
      </ContentStyles>
      <BackToTopButton />
    </>
  )
}
