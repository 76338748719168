import React from 'react'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'
import FooterStyles from './FooterStyles'
import logo from '../../assets/images/new-pio-logo.png'
import DonateButton from '../donate/button'

const links = [
  { text: 'Barbershop Harmony Society', link: 'http://www.barbershop.org/' },
  { text: 'Pioneer District QCA', link: 'http://www.pioneerqca.org/' },
]

const GenerateLinkList = () => (
  <div className="links">
    {links.reduce((elArr, link, i) => {
      elArr.push(
        <a className="light" href={link.link} key={`footer-link-${i}`}>
          {link.text}
        </a>
      )

      return elArr
    }, [])}
  </div>
)

export default function Footer() {
  return (
    <FooterStyles className="full-width">
      <div className="upper">
        <div className="content-width inner-content">
          <GenerateLinkList />

          <div className="logo-container">
            <img src={logo} alt="pioneer district logo" />
          </div>
          <div className="social">
            <div className="item">
              <div className="icon facebook">
                <FaFacebookSquare className="img" />
              </div>
              <a
                href="https://www.facebook.com/pioneerdistrict"
                rel="noopener noreferrer"
                target="_blank"
                className="light"
              >
                Pioneer District
              </a>
            </div>
            <div className="item">
              <div className="icon insta">
                <FaInstagram className="img" />
              </div>
              <a
                href="https://www.instagram.com/pioneerdistrictbhs/"
                rel="noopener noreferrer"
                target="_blank"
                className="light"
              >
                @pioneerdistrictbhs
              </a>
            </div>
          </div>
          <div className="donate-button">
            <DonateButton title="DONATE TO PIONEER" />
          </div>
        </div>
      </div>
      <div className="credits-container">
        <div className="credits-content">
          <p>
            Copyright © 2020-2024. Designed and created by Christopher Bateson
          </p>
        </div>
      </div>
    </FooterStyles>
  )
}
