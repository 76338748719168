import { graphql, Link, StaticQuery } from 'gatsby'
import React, { Component } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { ElLink } from './navUtils'
import PioLogo from '../../assets/images/new-pio-logo.png'

import NavStyles from './NavStyles'

class NavPanel extends Component {
  constructor(props) {
    super()
    this.navElements = props.navElements
    this.fullNav = null
    this.fixerEl = null
    this.classCount = 0
    this.menu = null
    this.dropDownArr = []
    this.toggleIcon = null
    this.navEls = this.createNavEls()
  }

  componentDidMount() {
    if (this.toggleIcon) {
      this.createToggleAction()
      this.menu.classList.remove('active')
    }
    if (this.menu) {
      this.createSubMenuAction()
    }
  }

  componentDidUpdate() {
    const { menu } = this
    if (menu) {
      menu.classList.remove('active')
    }

    if (menu.querySelector('.submenu-active')) {
      menu.querySelector('.submenu-active').classList.remove('submenu-active')
    }

    const onIcon = this.toggleIcon.querySelector('.on')
    const offIcon = this.toggleIcon.querySelector('.off')

    offIcon.style.display = 'none'
    onIcon.style.display = 'inline-block'
  }

  createSubMenuAction() {
    const { menu } = this

    const underNav = document.querySelector('.underNav')

    underNav.addEventListener('click', () => {
      const active = menu.querySelector('.submenu-active')

      if (active) {
        active.classList.remove('submenu-active')
      }
    })

    function toggleSubMenu() {
      if (this.classList.contains('submenu-active')) {
        this.classList.remove('submenu-active')
      } else if (menu.querySelector('.submenu-active')) {
        menu.querySelector('.submenu-active').classList.remove('submenu-active')
        this.classList.add('submenu-active')
      } else {
        this.classList.add('submenu-active')
      }
    }

    const itemsArr = menu.querySelectorAll('.item')
    const arrLength = itemsArr.length

    itemsArr.forEach((navItem, i) => {
      const submenu = navItem.querySelector('.submenu')
      if (submenu) {
        navItem.addEventListener('click', toggleSubMenu, false)
        if (i >= arrLength - 2) {
          submenu.classList.add('left-submenu')
        }
      }
    })
  }

  createToggleAction() {
    const { menu } = this

    const onIcon = this.toggleIcon.querySelector('.on')
    const offIcon = this.toggleIcon.querySelector('.off')
    offIcon.style.display = 'none'

    const icon = this.toggleIcon
    this.toggleIcon = icon

    icon.addEventListener('click', () => {
      if (menu.classList.contains('active')) {
        menu.classList.remove('active')

        offIcon.style.display = 'none'
        onIcon.style.display = 'inline-block'
        // adds the menu (hamburger) icon
        this.toggleIcon = <span>hi</span>
      } else {
        offIcon.style.display = 'inline-block'
        onIcon.style.display = 'none'

        menu.classList.add('active')
      }
    })
  }

  createNavEls() {
    return this.navElements.map(item => {
      const type = item.__typename
      const isDropdown = type === 'SanityNavCategoryDropdown'

      if (isDropdown) {
        return (
          <li
            key={item._key}
            ref={div => this.dropDownArr.push(div)}
            className="item has-submenu"
          >
            <ElLink navEl={item} isDropdown />
            <ul className="submenu">
              {item.dropdownLinks.map(el => (
                <li key={el._key} className="subitem">
                  <ElLink navEl={el} />
                </li>
              ))}
            </ul>
          </li>
        )
      }
      return (
        <li key={item._key} className="item">
          <ElLink navEl={item} />
        </li>
      )
    })
  }

  render() {
    return (
      <>
        <nav ref={div => (this.fullNav = div)}>
          <ul className="menu" ref={div => (this.menu = div)}>
            <li className="logo">
              <Link to="/" className="logo-content">
                <img src={PioLogo} alt="pioneer logo" />
                <h3>Pioneer District</h3>
              </Link>
            </li>
            {this.navEls}
            <span ref={div => (this.toggleIcon = div)}>
              <FaBars className="toggle on" />
              <FaTimes className="toggle off" />
            </span>
          </ul>
        </nav>
      </>
    )
  }
}

export default function Nav() {
  return (
    <StaticQuery
      query={graphql`
        query NavQuery {
          navLinks: allSanityNavBar {
            nodes {
              id
              categories {
                ... on SanityNavCategoryDropdown {
                  title
                  dropdownLinks {
                    title
                    _key
                    _rawLink
                  }
                  _key
                }
                ... on SanityNavCategoryLink {
                  title
                  _key
                  _rawLink
                }
              }
            }
          }
        }
      `}
      render={data => {
        const navLinks = data.navLinks.nodes[0]

        return (
          <NavStyles id="top">
            <NavPanel
              key="navElements"
              navElements={navLinks.categories}
              classCount={0}
            />
            <div id="spacer" />
          </NavStyles>
        )
      }}
    />
  )
}
