import React, { Component } from 'react'
import styled from 'styled-components'
import { IoMdArrowRoundUp as Arrow } from 'react-icons/io'
import gsap from 'gsap'
import scrollTo from 'gatsby-plugin-smoothscroll'

const BackToTopStyles = styled.div`
  position: fixed;
  right: 20px;
  bottom: -50px;
  /* bottom: 10px; */

  padding: 5px 20px 5px 15px;

  border: 1px solid black;
  border-radius: 20px;
  color: white;
  background-color: var(--link-hover);
  pointer-events: auto;
  cursor: pointer;
  font-weight: bold;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    h4 {
      padding: 0;
      margin: 0;
    }
  }

  &.hidden {
  }
`

const goToTop = () => {
  scrollTo('#top')
}

const handleScroll = () => {
  const shouldAppear = window.scrollY > 1500
  const btn = document.querySelector('.button')
  const { classList } = btn
  const includesHidden = Array.from(classList).includes('hidden')
  const duration = 0.5
  const ease = 'power1'

  if (shouldAppear && includesHidden) {
    btn.classList.remove('hidden')
    gsap.to(btn, { duration, y: -70, ease: `${ease}.out` })
  } else if (!shouldAppear && !includesHidden) {
    btn.classList.add('hidden')
    gsap.to(btn, { duration, y: 0, ease: `${ease}.in` })
  }
}

class BackToTopButton extends Component {
  constructor() {
    super()
  }

  componentDidMount() {
    window.addEventListener('scroll', handleScroll)
  }

  render() {
    return (
      <BackToTopStyles className="button hidden" onClick={goToTop}>
        <div className="content">
          <Arrow />

          <h4>Back to top</h4>
        </div>
      </BackToTopStyles>
    )
  }
}

export default function BackToTop() {
  return <BackToTopButton />
}
