import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'

const QuickNavStyles = styled.div`
  ul {
    /* margin: 0; */
    font-size: 1.5rem;

    list-style: none;
    display: flex;

    li {
      margin-right: 5px;

      &.current {
        font-weight: bold;
      }
    }
  }
`

export default function QuickNavGenerator({ location }) {
  const { pathname } = location

  if (pathname !== '/') {
    const finalPathArr = [location.origin]

    const pathArr = pathname.match(/\/[^/]*/gi)
    finalPathArr.push(...pathArr)

    let url = ''

    return (
      <QuickNavStyles>
        <ul key="quickNav">
          {finalPathArr.reduce((elArr, path, i) => {
            if (i !== 0) {
              url += path
            }

            const curUrl = i === 0 ? '/' : url
            let text = i === 0 ? 'Home' : path.split('/')[1]

            const limit = 20
            if (text.length > limit) {
              text = `${text.slice(0, limit)}`.split('-')
              text = `${text.splice(0, text.length - 1 || 1).join('-')}...`
            }

            if (i === finalPathArr.length - 1) {
              elArr.push(
                <li key={`quick-nav-item-${url}`} className="current">
                  {text}
                </li>
              )
              return elArr
            }

            const liEl = (
              <li key={`quick-nav-item-${url}`}>
                <Link className="dark" rel="canonical" to={curUrl}>
                  {text}
                </Link>
              </li>
            )

            elArr.push(liEl)
            elArr.push(<li key={`quick-nav-arrow-${i}`}>{'>'}</li>)

            return elArr
          }, [])}
        </ul>
      </QuickNavStyles>
    )
  }
  return <></>
}
